import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyAdvice = () => (
  <Layout>
    <SEO title="Aviso de privacidad" />
    <div className="">
      <h1 className="text-center text-3xl py-16">AVISO DE PRIVACIDAD</h1>
      <p className="pb-6">
        FARCORPE S.A, mejor conocido como FARCORPE, con domicilio en calle Luis
        Lusati, Manzana 12, Lote 14, colonia Colonia México Nuevo, ciudad Ciudad
        López Mateos, municipio o delegación Atizapán de Zaragoza, c.p. 52966,
        en la entidad de Estado de México, país México, y portal de internet
        farcorpe.com, es el responsable del uso y protección de sus datos
        personales, y al respecto le informamos lo siguiente:
      </p>
      <h2 className="font-bold py-4">
        ¿Para qué fines utilizaremos sus datos personales?
      </h2>
      <p className="pb-6">
        De manera adicional, utilizaremos su información personal para las
        siguientes finalidades secundarias que no son necesarias para el
        servicio solicitado, pero que nos permiten y facilitan brindarle una
        mejor atención:
      </p>
      <ul>
        <li>
          Cumplir con aquellas obligaciones legales y jurídicas establecidas por
          nuestras autoridades.
        </li>
        <li>Utilizar y/o tratar sus datos con fines estadísticos.</li>
        Utilizar y/o tratar sus datos con fines publicitarios y de fomento en
        mejora de la imagen de Farcorpe.
        <li>Utilizar sus datos como base de referencia-cartera de clientes.</li>
        <li>
          Enviarle/notificarle a su domicilio, correo electrónico, teléfono y/o
          fax invitaciones, ofertas o cualquier iniciativa con fines de
          prospección comercial.
        </li>
        <li>
          Contactarle-notificarle ante algún posible riesgo sanitario de acuerdo
          a reportes de farmacovigilancia y/o tecnovigilancia.
        </li>
        <li>l Prospección comercial</li>
      </ul>
      <p className="pb-6">
        En caso de que no desee que sus datos personales se utilicen para estos
        fines secundarios, indíquelo a continuación: No consiento que mis datos
        personales se utilicen para los siguientes fines:
      </p>
      <ul>
        <li>
          [ ] Cumplir con aquellas obligaciones legales y jurídicas establecidas
          por nuestras autoridades.
        </li>
        <li>[ ] Utilizar y/o tratar sus datos con fines estadísticos.</li>
        <li>
          [ ] Utilizar y/o tratar sus datos con fines publicitarios y de fomento
          en mejora de la imagen de Farcorpe.
        </li>
        <li>
          [ ] Utilizar sus datos como base de referencia-cartera de clientes.
        </li>
        <li>
          [ ] Enviarle/notificarle a su domicilio, correo electrónico, teléfono
          y/o fax invitaciones, ofertas o cualquier iniciativa con fines de
          prospección comercial.
        </li>
        <li>
          [ ] Contactarle-notificarle ante algún posible riesgo sanitario de
          acuerdo a reportes de farmacovigilancia y/o tecnovigilancia.
        </li>
        <li>[ ] Prospección comercial</li>
      </ul>
      <p className="pb-6">
        La negativa para el uso de sus datos personales para estas finalidades
        no podrá ser un motivo para que le neguemos los servicios y productos
        que solicita o contrata con nosotros.
      </p>
      <h2 className="font-bold py-4">
        ¿Qué datos personales utilizaremos para estos fines?
      </h2>
      <p className="pb-6">
        Para llevar a cabo las finalidades descritas en el presente aviso de
        privacidad, utilizaremos los siguientes datos personales:
      </p>
      <ul>
        <li>Datos de identificación</li>
        <li>Datos de contacto</li>
      </ul>
      <h2 className="font-bold py-4">
        ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
        oponerse a su uso?
      </h2>
      <p className="pb-6">
        Usted tiene derecho a conocer qué datos personales tenemos de usted,
        para qué los utilizamos y las condiciones del uso que les damos
        (Acceso). Asimismo, es su derecho solicitar la corrección de su
        información personal en caso de que esté desactualizada, sea inexacta o
        incompleta (Rectificación); que la eliminemos de nuestros registros o
        bases de datos cuando considere que la misma no está siendo utilizada
        adecuadamente (Cancelación); así como oponerse al uso de sus datos
        personales para fines específicos (Oposición). Estos derechos se conocen
        como derechos ARCO.
      </p>
      <p className="pb-6">
        Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
        presentar la solicitud respectiva a través del siguiente medio:
      </p>
      <p className="pb-6">
        Los titulares que deseen ejercer sus derechos de acceso, rectificación,
        cancelación, oposición (ARCO) y la revocación del consentimiento,
        mediante una solicitud al correo electrónico contacto@farcorpe.com Con
        relación al procedimiento y requisitos para el ejercicio de sus derechos
        ARCO, le informamos lo siguiente:
      </p>
      <p className="pb-6">
        a) ¿A través de qué medios pueden acreditar su identidad el titular y,
        en su caso, su representante, así como la personalidad este último? El
        nombre del titular, domicilio y contacto <br />
        b) ¿Qué información y/o documentación deberá contener la solicitud?
        Acompañar documentos oficiales que acrediten la identidad del titular{" "}
        <br />
        c) ¿En cuántos días le daremos respuesta a su solicitud? Farcorpe dará
        respuesta en un tiempo no mayor a 30 días hábiles. <br />
        d) ¿Por qué medio le comunicaremos la respuesta a su solicitud? email{" "}
        <br />
        e) ¿En qué medios se pueden reproducir los datos personales que, en su
        caso, solicite? Anexar cualquier elemento o documento que auxilie la
        localización de los datos Los datos de contacto de la persona o
        departamento de datos personales, que está a cargo de dar trámite a las
        solicitudes de derechos ARCO, son los siguientes: <br />
        a) Nombre de la persona o departamento de datos personales: contacto
        FARCORPE <br />
        b) Domicilio: calle Luis Lusati, Manzana 12, Lote 14, colonia México
        Nuevo, ciudad López Mateos, municipio o delegación Atizapán de Zaragoza,
        c.p. 52966, en la entidad de Estado de México, país México
      </p>
      <h2 className="font-bold py-4">
        Usted puede revocar su consentimiento para el uso de sus datos
        personales
      </h2>
      <p className="pb-6">
        Usted puede revocar el consentimiento que, en su caso, nos haya otorgado
        para el tratamiento de sus datos personales. Sin embargo, es importante
        que tenga en cuenta que no en todos los casos podremos atender su
        solicitud o concluir el uso de forma inmediata, ya que es posible que
        por alguna obligación legal requiramos seguir tratando sus datos
        personales. Asimismo, usted deberá considerar que para ciertos fines, la
        revocación de su consentimiento implicará que no le podamos seguir
        prestando el servicio que nos solicitó, o la conclusión de su relación
        con nosotros.
      </p>
      <p className="pb-6">
        Para revocar su consentimiento deberá presentar su solicitud a través
        del siguiente medio: contacto@farcorpe.com Para conocer el procedimiento
        y requisitos para la revocación del consentimiento, ponemos a su
        disposición el siguiente medio:
        <a href="https://www.covid19test.com.mx/aviso">
          www.covid19test.com.mx/aviso
        </a>
      </p>
      <h2 className="font-bold py-4">
        ¿Cómo puede limitar el uso o divulgación de su información personal?
      </h2>
      <p className="pb-6">
        Con objeto de que usted pueda limitar el uso y divulgación de su
        información personal, le ofrecemos los siguientes medios:
      </p>
      <h2 className="font-bold py-4">
        ¿Cómo puede conocer los cambios en este aviso de privacidad?
      </h2>
      <p className="pb-6">
        El presente aviso de privacidad puede sufrir modificaciones, cambios o
        actualizaciones derivadas de nuevos requerimientos legales; de nuestras
        propias necesidades por los productos o servicios que ofrecemos; de
        nuestras prácticas de privacidad; de cambios en nuestro modelo de
        negocio, o por otras causas.
      </p>
      <p className="pb-6">
        Nos comprometemos a mantenerlo informado sobre los cambios que pueda
        sufrir el presente aviso de privacidad, a través de:
        www.covid19test.com.mx/aviso.
      </p>
      <p className="pb-6">
        El procedimiento a través del cual se llevarán a cabo las notificaciones
        sobre cambios o actualizaciones al presente aviso de privacidad es el
        siguiente:
      </p>
      <a href="/">www.covid19test.com.mx/aviso</a> <br />
      <small>Última actualización: 08/02/2022</small>
    </div>
  </Layout>
)

export default PrivacyAdvice
